<template>
  <div>
    <v-row justify="center" no-gutters>
      <v-col lg="8" cols="12">
        <new-perseu-card
          title="Próximos agendamentos"
          subtitle="Obs: Horário em formato 24h de acordo com seu horário local."
        >
          <v-list v-if="hasAppointments">
            <v-list-item
              v-for="appointment in appointments"
              :key="appointment.id"
              three-line
              class="border-bottom-grey"
              @click="openActions(appointment)"
            >
              <v-list-item-icon>
                <tooltip-icon
                  :icon="getIcon(appointment.status)"
                  :color="getIconColor(appointment.status)"
                  :text="appointment.formattedStatus"
                  large
                />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="py-1">
                  {{ appointment.startAt | formatDateTimeFriendly }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Terapeuta: {{ appointment.therapistName }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Status: {{ appointment.formattedStatus }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <template v-else>
            <empty-list text="Nenhum agendamento marcado." />
          </template>
        </new-perseu-card>
        <new-perseu-card class="mt-5" title="Últimos agendamentos (30 dias)">
          <v-list v-if="hasPastAppointments" flat>
            <v-list-item
              v-for="pastAppointment in pastAppointments"
              :key="pastAppointment.id"
              three-line
              class="border-bottom-grey"
              @click="openActions(pastAppointment)"
            >
              <v-list-item-icon>
                <icon-status
                  :status="pastAppointment.status"
                  :text="pastAppointment.formattedStatus"
                />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="py-1">
                  {{ pastAppointment.startAt | formatDateTimeFriendly }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Terapeuta: {{ pastAppointment.therapistName }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Status: {{ pastAppointment.formattedStatus }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <template v-else>
            <empty-list text="Nenhum agendamento realizado." />
          </template>
        </new-perseu-card>
      </v-col>
    </v-row>
    <list-actions ref="actions" @cancelled="cancelledAppointment" />
    <fixed-button
      text="Novo agendamento"
      icon="alarm_add"
      @click="newAppointment"
    />
  </div>
</template>

<script>
import {
  getAppointmentsByPatient,
  getPastAppointmentsByPatient,
} from "@/services/external/appointments-service";

export default {
  name: "AppointmentsList",
  components: {
    ListActions: () =>
      import(
        "@/domains/appointments/patient/presentation/components/ListActions"
      ),
    IconStatus: () =>
      import(
        "@/domains/appointments/therapist/presentation/components/IconStatus"
      ),
  },
  data: () => ({
    appointments: [],
    pastAppointments: [],
    tooltipButtonNew: true,
  }),
  computed: {
    hasAppointments() {
      return !!this.appointments && this.appointments.length > 0;
    },
    hasPastAppointments() {
      return !!this.pastAppointments && this.pastAppointments.length > 0;
    },
  },
  created() {
    this.findAppointments();
    setTimeout(() => (this.tooltipButtonNew = false), 2000);
  },
  methods: {
    async findAppointments() {
      try {
        this.$store.dispatch("loading/openDialog");
        const [responseAppointments, responseLastAppointments] =
          await Promise.all([
            getAppointmentsByPatient(),
            getPastAppointmentsByPatient(),
          ]);
        this.appointments = responseAppointments.data;
        this.pastAppointments = responseLastAppointments.data;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    getIcon(status) {
      const mapConditions = new Map([
        [1, "hourglass_empty"],
        [2, "check_circle_outline"],
        [3, "done_all"],
        [4, "cancel"],
      ]);
      return mapConditions.get(status);
    },
    getIconColor(status) {
      const mapConditions = new Map([
        [1, "warning"],
        [2, "success"],
        [3, "blue darken-2"],
        [4, "error"],
      ]);
      return mapConditions.get(status);
    },
    newAppointment() {
      this.$store.commit("appointments/patient/clearState");
      this.$router.push({ name: "newAppointmentPatient" });
    },
    openActions(item) {
      this.$refs.actions.open(item);
    },
    cancelledAppointment({ appointmentId, newStatus }) {
      console.log(appointmentId, newStatus);
      const index = this.appointments.findIndex(
        (appointment) => appointment.id === appointmentId
      );
      this.appointments.splice(
        index,
        1,
        Object.assign(this.appointments[index], { status: newStatus })
      );
    },
  },
};
</script>

<style scoped></style>
